<template>
  <div class="deviceReport">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <button @click="handleOutput">生成PDF</button>
        <!-- 内容 -->
        <div class="contant_O pdf-panel" id="class_pdf">
          <headTitle :objAll="objAll"></headTitle>
          <directory :objAll="objAll" :nameList="nameList"></directory>
          <div class="maintablepage-header">
            <div class="maintablepage-header-name">设备报告</div>
          </div>
          <!-- 2.1. 设备总数 -->
          <div class="cont_z">
            <div class="msrStr">1. 设备总数</div>
            <div class="marStyle">{{ objAll.title }}的能源系统在报告期内，共有 {{ numtotal }} 个能源设备，关键运行指标如下：</div>
            <el-table class="app-table" :data="tableData" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="name">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="codeValue">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    <!-- 升 -->
                    <!-- <i class="el-icon-caret-top" style="color: red;font-size: 24px;line-height: 40px;"></i> -->
                    {{ scope.row.type }}
                    <!-- 降 -->
                    <!-- <i class="el-icon-caret-bottom" style="color: green;font-size: 24px;line-height: 40px;"></i> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div style="margin: 10px 0;">设备组成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart1" id="chart1"></div>
          </div>
          <!-- 2.2. 配电设备运行分析 -->
          <div class="cont_z">
            <div class="msrStr">2. 配电设备运行分析 </div>
            <div class="marStyle">运行期内配电设备运行总览表如下：</div>
            <el-table class="app-table" :data="tableData1" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="marStyle">运行期内配电设备利用率：</div>
            <el-table class="app-table" :data="tableData2" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div style="margin: 10px 0;">设备利用率曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart2" id="chart2"></div>
          </div>
          <!-- 2.3. 光伏设备运行分析 -->
          <div class="cont_z">
            <div class="msrStr">3. 光伏设备运行分析</div>
            <div class="marStyle">运行期内光伏设备运行总览表如下：</div>
            <el-table class="app-table" :data="tableData3" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="比例" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div style="margin: 10px 0;">运行期内等效利用小时数曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart3" id="chart3"></div>
          </div>
          <!-- 2.4. 储能设备运行分析 -->
          <div class="cont_z">
            <div class="msrStr">4. 储能设备运行分析</div>
            <div class="marStyle">运行期内储能设备运行总览表如下：</div>
            <el-table class="app-table" :data="tableData4" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="name" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="valueCode">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div style="margin: 10px 0;">运行期内放电量曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart4" id="chart4"></div>
          </div>
        </div>
        <!--  -->
        <div class="pdf-header"
          style="font-weight: bold; padding:15px 8px; width: 100%; color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <!-- 页头 -->
        </div>
        <div class="pdf-footer"
          style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
            页尾
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
            第<div class="pdf-footer-page"></div>页 / 共<div class="pdf-footer-page-count"></div>页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTitle from './homePage.vue'
import directory from './directory'
// import PdfLoader from './html2pdf.js'
import { outputPDF } from './outputPDF'
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  components: { headTitle, directory },
  data() {
    return {
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      numtotal: 0,
      objAll: {},
      nameList: [
        {
          name: '设备报告',
          page: 2
        },
        {
          name: '设备总数',
          page: 2
        },
        {
          name: '配电设备运行分析',
          page: 2
        },
        {
          name: '光伏设备运行分析',
          page: 3
        },
        {
          name: '储能设备运行分析',
          page: 3
        }
      ],
    }
  },
  created() {
    let allQuery = this.$route.query
    this.objAll = {
      title: allQuery.regionName + allQuery.region2 + '系统',
      times: this.$moment(allQuery.date1).format('YYYY [年] ') + (allQuery.region3 == '天' ? this.$moment(allQuery.date1).format("MMM Do") : (allQuery.region3 == '月' ? (this.$moment(allQuery.date1).format("MMM [月]") + '度') : (allQuery.region3 + '度'))) + allQuery.region1,
      deptCode: allQuery.region,
      startDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).startOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).startOf("month").format('YYYY-MM-DD HH:mm:ss')),
      endDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).endOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).endOf("month").format('YYYY-MM-DD HH:mm:ss')),
      queryType: 'month',
    }
    if (allQuery.region3 == '月') {
      this.objAll["queryType"] = "month"
    } else if (allQuery.region3 == '年') {
      this.objAll["queryType"] = "year"
    } else if (allQuery.region3 == '天') {
      this.objAll["queryType"] = "day1h"
      this.objAll.startDate = this.$moment(allQuery.date1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.objAll.endDate = this.$moment(allQuery.date1).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
    this.getAlldata()
    this.getAlldata1()
    this.getAlldata2()
    this.getAlldata3()
    this.getrate()
    this.getrateOf()
    this.getincome()
    this.getelectric()
  },
  mounted() {

    // const xxx = document.getElementById('class_pdf')  // 需要导出部分页面的id名
    // this.pdfDownLoader = new PdfLoader(xxx, 'fileName', 'question-table')  // fileName -->导出文件名,  question-table -->防止被截断的class名
  },
  methods: {
    handleOutput() {
      let _this = this;
      const element = document.querySelector('.pdf-panel');
      const header = document.querySelector('.pdf-header');
      const footer = document.querySelector('.pdf-footer');
      outputPDF({
        element: element,
        footer: footer,
        header: header,
        contentWidth: 500,
        filename: '设备报告',
        _this: _this,
        dev_url: Config.dev_url
      })
    },
    //上传
    uploadreport(files) {

      var formData = new FormData();
      formData.append("fileList", this.pdfDownLoader.mypdf);
      formData.append("appkey", "WORKORDER");
      formData.append("recordid", "1635320176178049026");
      var config = {
        //添加请求头
        headers: { "Content-Type": "multipart/form-data", },
        transformResponse: [response => JSON.parse(response.replace(/("fileid":)(\d{0,})(,)/g, '$1' + '"' + '$2' + '"' + '$3'))],
        // transformResponse: [response => JSON.parse(response.replace(/(?:"fileid":)(\d{0,})/g, '"$1"'))],
      }
      const url = Config.dev_url + "/api-file/file/uploads";
      this.$axios.post(url, formData, config,).then((res) => {
        if (res.data.code == 0) {
          // let aa = res.data.data[0].fileid.replace(/("fileid":)(\d{0,})(,)/g, '$1'+'"'+'$2'+'"'+'$3')
          // console.log(aa)
          this.$message.success(res.data.msg)
          let obj = {}
          console.log(res)


          this.$axios({
            url: Config.dev_url + "/api-apps/app/STATISTICALREPORTFILEINFO",
            method: "post",
            data: {
              REPORTORG: this.$route.query.regionName,
              TIMEAROUND: this.$route.query.region3,
              FILEID: res.data.data[0].fileid,
              FILENAME: '设备报告'

            }
          }).then(res => {
            alert('上传成功')
          })

        } else {
          // this.$message.warning(res.data.msg)
        }
      })

    },
    handleExport() {
      this.pdfDownLoader.outPutPdfFn('设备报告')
      let vm = this
      setTimeout(() => {
        vm.uploadreport()
      }, 10000);
    },
    sumNaN(sum) {
      return Number(sum == 'NaN' ? null : (sum == 'null' ? null : sum))
    },
    getincome() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.tableData3.push({
          namecode: '节约碳排（吨）',
          name: Number(allData.carbon),
          type: '',
          site: ''
        })
        this.tableData3.push({
          namecode: '累计收益（元）',
          name: (this.sumNaN(allData.surplus) + this.sumNaN(allData.self)).toFixed(2),
          type: '',
          site: ''
        })
      });
    },
    getrateOf() {
      let data = { "deptCode": this.objAll.deptCode }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/powerusage/total";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.tableData2.push({
          namecode: '功率利用率',
          name: allData.percent + '%',
          type: '',
          site: ''
        })
      });
    },
    getrate() {
      let data = { "deptCode": this.objAll.deptCode }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/usagerate/total";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.tableData2.push({
          namecode: '设备利用率',
          name: allData.percent + '%',
          type: '',
          site: ''
        })
        this.initEchart1(allData)
      });
    },
    getAlldata() {
      let data = { "deptCode": this.objAll.deptCode }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/statistics";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        let numdata = 0
        allData.forEach((item) => {
          numdata += Number(item.total == 'null' ? null : item.total)
        })
        this.tableData.push({
          name: '总设备数',
          codeValue: numdata,
          type: '',
          site: ''
        })
        this.numtotal = numdata
        this.initEchart(allData)
      });
    },
    sumList(arr) {
      return arr.reduce(function (prev, curr, idx, arr) {
        return Number(prev == 'null' ? null : prev) + Number(curr == 'null' ? null : curr);
      });
    },
    getAlldata1() {
      let data = { "deptCode": this.objAll.deptCode, "check": "总发电量", "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/ranking";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data.seriesData;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.name == '发电量') {
              this.tableData3.push({
                namecode: '累计发电量（度）',
                name: this.sumList(item.value),
                type: '',
                site: ''
              })
            }
          })
        } else {
          this.tableData3.push({
            namecode: '累计发电量（度）',
            name: '',
            type: '',
            site: ''
          })
        }
      });
    },
    getAlldata2() {
      let data = { "deptCode": this.objAll.deptCode, "assetNumber": "", "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/inverter/euih";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        let seriesDatax = res.data.data.total
        if(seriesDatax==null){
          return
        }
        let sums = 0
        seriesDatax.forEach((item) => {
          sums += this.sumNaN(item[Object.keys(item)])
        })
        this.tableData3.push({
          namecode: '等效小时数（小时）',
          name: Number(sums).toFixed(2),
          type: '',
          site: ''
        })
        setTimeout(()=>{
          this.initEchart2(allData)
        },200)
      });
    },
    getAlldata3() {
      let data = { "deptCode": this.objAll.deptCode, "queryType": this.objAll.queryType, "equipment": "121027202", "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/charging/quantity";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.tableData4.push({
          name: '累计放电量（度）',
          valueCode: allData['今日总充电量'],
          type: '',
          site: ''
        })
        setTimeout(()=>{
          this.initEchart3(allData)
        },200)
      });
    },
    getelectric() {
      let data = { "deptCode": this.objAll.deptCode, "queryType": this.objAll.queryType, "startDate": this.objAll.startDate, "endDate": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/income";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.tableData4.push({
          name: '累计收益（元）',
          valueCode: this.sumNaN(allData.surplus) + this.sumNaN(allData.self) + this.sumNaN(allData.storage),
          type: '',
          site: ''
        })
      });
    },
    // 饼图
    initEchart(allData) {
      let chartInit = echarts.init(this.$refs.chart1, "chart1");
      var syjgdata = [];
      allData.forEach((item) => {
        syjgdata.push({
          name: item.machine_type,
          value: item.total
        })
      });
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF", "#3E60FF", '#fff'];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    // 折线
    initEchart1(allData) {
      let chartInit = echarts.init(this.$refs.chart2, "chart2");
      var getsjjg = ['设备利用率'];
      var syjgdata = [allData.percent];
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: getsjjg
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: allData.equipmentNam,
          data: syjgdata,
          type: 'line'
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart2(allData) {
      let chartInit = echarts.init(this.$refs.chart3, "chart3");
      var getsjjg = allData.categoryData;
      var getsjjgrs = allData.seriesData;
      var syjgdata = [];
      if(getsjjgrs.length == 0){
        syjgdata.push({
          name: '',
          data: [],
          type: 'line'
        })
      }else{
        getsjjgrs.forEach((item) => {
          syjgdata.push({
            name: item.name + '等效小时数',
            data: item.value,
            type: 'line'
          })
        })
      }
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        legend: {
          left: 'center',
          bottom: '5%'
        },
        xAxis: {
          type: 'category',
          data: getsjjg
        },
        yAxis: {
          type: 'value'
        },
        series: syjgdata
      }

      chartInit.setOption(option13);
    },
    initEchart3(allData) {
      let chartInit = echarts.init(this.$refs.chart4, "chart4");
      var getsjjg = allData.date;
      var syjgdata = allData['充电'];

      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        legend: {
          left: 'center',
          bottom: '5%'
        },
        xAxis: {
          type: 'category',
          data: getsjjg
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '发电',
          data: syjgdata,
          type: 'line'
        }]
      }

      chartInit.setOption(option13);
    },
  }
}
</script>
<style lang="less">
.deviceReport {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

#maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
      width: calc(100% - 40px);
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .maintablepage-header-name {
    font-size: 38px;
  }
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#maintablepage .maintablepage-content {
  overflow-y: auto !important;
}

.contant_O {
  overflow-y: scroll;
  position: relative;
}

.cont_z {
  padding: 10px 0;
  font-size: 30px;
}

.marStyle {
  margin: 20px;
}

.msrStr {
  margin: 20px 0;
}

/deep/.app-table .el-table__header thead tr th .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

/deep/.app-table .el-table__body tbody tr td .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}
</style>